import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA , NgModule} from '@angular/core';
/*@NgModule({

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})*/
@Component({
  selector: 'app-ongoingpro',
  templateUrl: './ongoingpro.component.html',
  styleUrls: ['./ongoingpro.component.css']
})
export class OngoingproComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
