<section id="inner-headline">
    <div class="container">
      <div class="row">
        <div class="span12">
          <div class="inner-heading">
            <ul class="breadcrumb">
              <li><a href="">Home</a> <i class="icon-angle-right"></i></li>
              <li><a href="#">Pages</a> <i class="icon-angle-right"></i></li>
              <li class="active">Who we are</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="container">
      <div class="row">

        <div class="span12">
          <h4 class="title"><strong>Who we are</strong></h4>
          <!-- start: Accordion -->
          <div class="accordion" id="accordion2">
            <div class="accordion-group">
              <div id="collapseOne" class="accordion-body collapse in">
                <div class="accordion-inner">
                ARD Solution was first established on 2014.ARD System Solution is an young and dynamic company specializing in web based, desktop based software, website, ERP, mobile application, database Design and development,
                Artificial Intelligent in Bangladesh. Beside that we have expertise in application support and consultancy service. <br><br>
<div>
<h3>Best quality with affordable price: </h3>We try to provide you the high quality assured application with affordable price. We trust on customer relation. Customer satisfaction is our main concern.
</div>
<br><br>
<div>
<h3>Web/desktop based software:</h3>We have expertise in web and desktop based software implementation. We have developed POS system, accounting system, inventory system, school management software, hospital management software, HR module, Full ERP system etc. as per customer’s requirement.
</div>
<br><br>
<div>
<h3>Web site and portal development:</h3>
We have developed lots of websites/portals which include corporate company, NGO, aviation industry. We also maintain website/portal with affordable price and provide support accordingly. 
</div>
<br><br>
<div>
<h3>Mobile app Development:  </h3>
We develop different type of mobile app. based on our client requirements.
</div>
<br><br>
<div>
<h3>Consultancy:</h3> We provide our consultancy service and expert suggestion for critical technical problems.
</div>
<br><br>
<div>
<h3>Database Design and Development:  </h3> We have huge expertise of designing any type of complex database which varies from modern e-commerce, web portal to ERP. We also provide required database development service to our valuable customer as per requirement. We are capable to provide required support and maintenance service to our customer.
</div>
<br><br>
<div>
<h3>Artificial Intelligence and Analytics:</h3>
We have our own artificial intelligence based OCR (Optical character Recognition) system which is our prime product. We also have expertise in data analytics and report generation using automated process.
</div>
<br><br>
<div>
<h3>Robotic process Automation (RPA):  </h3>
We are fully capable of developing and maintaining of Robotic process automation using our own Framework. Our RPA tool is fully capable of finishing pre-defined scheduled work electronically without any human touch.
</div>

                </div>
              </div>
            </div>
          </div>
          <!--end: Accordion -->

          

        </div>

      </div>
    </div>
  </section>
  <app-footer></app-footer>
