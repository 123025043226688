import { BrowserModule } from '@angular/platform-browser';
import { NgModule ,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';

import { AppRoutingModule  } from './app-routing.module';

import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MainpageComponent } from './mainpage/mainpage.component';
import { OurproductComponent } from './ourproduct/ourproduct.component';
import { WhoweareComponent } from './whoweare/whoweare.component';
import { MsgchairmanComponent } from './msgchairman/msgchairman.component';
import { ClientlistComponent } from './clientlist/clientlist.component';
import { OngoingproComponent } from './ongoingpro/ongoingpro.component';
import { FinishedproComponent } from './finishedpro/finishedpro.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainpageComponent,
    OurproductComponent,
    WhoweareComponent,
    MsgchairmanComponent,
    ClientlistComponent,
    OngoingproComponent,
    FinishedproComponent,
    ContactusComponent,
    AboutusComponent
  ],
  /*schemas: [
    NO_ERRORS_SCHEMA,
    CUSTOM_ELEMENTS_SCHEMA
  ],*/
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
