import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA , NgModule} from '@angular/core';
/*@NgModule({

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})*/
@Component({
  selector: 'app-msgchairman',
  templateUrl: './msgchairman.component.html',
  styleUrls: ['./msgchairman.component.css']
})
export class MsgchairmanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
