  
  <!-- section featured -->
<section id="featured">

    <!-- slideshow start here -->

    <div class="camera_wrap" id="camera-slide">

      <!-- slide 1 here -->
      <div data-src="assets/img/slides/camera/slide1/img1.jpg">
        <div class="camera_caption fadeFromLeft">
          <div class="container">
            <div class="row">
              <div class="span6">
                <h2 class="animated fadeInDown"><strong>ARD Solution <span class="colored">for customer</span></strong></h2>
                <p class="animated fadeInUp"> We are professional and we understand the importance of software’s strategies 4
                  to your core business. Your business's success can be profoundly influenced by a smart software solution strategy.</p>
                <a [routerLink]="'/product'" class="btn  btn-large animated fadeInUp">
                                          <i class="icon-download"></i> Read More
                                      </a>
                                </div>
              <div class="span6">
                <img src="assets/img/slides/camera/slide1/screen.png" alt="" class="animated bounceInDown delay1" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- slide 2 here -->
      <div data-src="assets/img/slides/camera/slide2/img1.jpg">
        <div class="camera_caption fadeFromLeft">
          <div class="container">
            <div class="row">
              <div class="span6">
                <img src="assets/img/slides/camera/slide2/iMac.png" alt="" />
              </div>
              <div class="span6">
                <h2 class="animated fadeInDown"><strong>ARD Solution <span class="colored">for you</span></strong></h2>
                <p class="animated fadeInUp">We try to provide you a total software solution for your business in simple way.</p>
                <a [routerLink]="'/whoweare'" class="btn btn-large animated fadeInUp">
                                          <i class="icon-download"></i> Read More
                                      </a>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- slide 3 here -->
      <div data-src="assets/img/slides/camera/slide2/img1.jpg">
        <div class="camera_caption fadeFromLeft">
          <div class="container">
            <div class="row">
              <div class="span12 aligncenter">
                <h2 class="animated fadeInDown"><strong><span class="colored">ARD Solution</span> <!-- <span class="colored">Technolgy...</span>--> ensures high performance</strong></h2>
                <p class="animated fadeInUp">ARD Solution is an exciting place to be at. Our diversity is our motivation. 
                  We take pride in being able to provide the experience of challenge, reward, variety, success and helping others – all under one roof.</p>
                <img src="assets/img/slides/camera/slide3/browsers.png" alt="" class="animated bounceInDown delay1" />
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- slideshow end here -->

  </section>
  <!-- /section featured -->

  <section id="content">
    <div class="container">


      <div class="row">
        <div class="span12">
          <div class="row">
            <div class="span4">
              <div class="box flyLeft">
                <div class="icon">
                  <i class="ico icon-circled icon-bgdark icon-star active icon-3x"></i>
                </div>
                <div class="text">
                  <h4>Solution <strong>for clients</strong></h4>
                  <p>
                    Here at ARD Solution we have aligned our objectives with those of our clients. The outcome is fully based on the urge to deliver superior software solutions. 
                  </p>
                  <a [routerLink]="'/product'">Read More</a>
                </div>
              </div>
            </div>

            <div class="span4">
              <div class="box flyIn">
                <div class="icon">
                  <i class="ico icon-circled icon-bgdark icon-dropbox active icon-3x"></i>
                </div>
                <div class="text">
                  <h4>List of <strong>clients</strong></h4>
                  <p>
                    We have worked with different type of clients. We have already developed ERP, E-commerce, Website, Web portal, mobile app and many more.
                  </p>
                  <a [routerLink]="'/clientlist'">Read More</a>
                </div>
              </div>
            </div>
            <div class="span4">
              <div class="box flyRight">
                <div class="icon">
                  <i class="ico icon-circled icon-bgdark icon-laptop active icon-3x"></i>
                </div>
                <div class="text">
                  <h4>Who <strong>we are</strong></h4>
                  <p>
                    ARD Solution is software firm which actively working since 2014. We have huge expertise in software enabled area. We have very talented and expert group of people with us.
                  </p>
                  <a [routerLink]="'/whoweare'">Read More</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="span12">
          <div class="solidline"></div>
        </div>
      </div>

      <div class="row">
        <div class="span12">
          <div class="row">
            <div class="span12">
              <div class="aligncenter">
                <h3>Our <strong>own Software Products</strong></h3>
                <p>We have our own developed software products. These products are treated as readymade one.
                   We have huge expertise in small to large scale ERP, e-commerce, website, web portal, 
                   mobile app, Robotic Process Automation, OCR, database design and many more.</p>
              </div>
            </div>
          </div>


          <div class="row">

            <div class="span3">
              <div class="pricing-box-wrap special animated-slow flyIn">
                <div class="pricing-heading">
                  <h5>ARD Smart ERP</h5>
                  <br>
                </div>
                
               
                <div class="pricing-action">
                  <a [routerLink]="'/product'" class="btn btn-medium">Read more</a>
                </div>
              </div>
            </div>

            <div class="span3">
              <div class="pricing-box-wrap special animated-slow flyIn">
                <div class="pricing-heading">
                  <h5>E-commerce</h5>
                  <br>
                </div>
                                  <div class="pricing-action">
                  <a [routerLink]="'/product'" class="btn btn-medium"> Read more</a>
                </div>
              </div>
            </div>

            <div class="span3">
              <div class="pricing-box-wrap special animated-slow flyIn">
                <div class="pricing-heading">
                  <h5>OCR Solution</h5>
                  <br>
                </div>
                <div class="pricing-action">
                  <a [routerLink]="'/product'" class="btn btn-medium">Read more</a>
                </div>
              </div>
            </div>

            <div class="span3">
              <div class="pricing-box-wrap special animated-slow flyIn">
                <div class="pricing-heading">
                  <h5>Robotic Process Automation (RPA) Tool</h5>
                </div>
                
               <div class="pricing-action">
                  <a [routerLink]="'/product'" class="btn btn-medium"> Read more</a>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>



      <div class="row">
        <div class="span12 aligncenter">
          <h3 class="title"> <strong>About </strong>  ARD Solution</h3>
          <div class="blankline30"></div>

          <ul class="bxslider">
            <li>
              <blockquote>
              ARD Solution is committed to provide highest quality product and support to our customer at any cost. 
             </blockquote>
              
           
          </ul>

        </div>
      </div>

    </div>
  </section>
  <app-footer></app-footer>


