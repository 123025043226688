
    
<section id="inner-headline">
    <div class="container">
      <div class="row">
        <div class="span12">
          <div class="inner-heading">
            <ul class="breadcrumb">
              <li><a href="">Home</a> <i class="icon-angle-right"></i></li>
              <li><a href="">Pages</a> <i class="icon-angle-right"></i></li>
              <li class="active">Our Products</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="container">
      <div class="row">

        <div class="span12">
          <h4 class="title"><strong>Our Products</strong></h4>
          <!-- start: Accordion -->
          <div class="accordion" id="accordion2">
            <div class="accordion-group">
              <!--<div class="accordion-heading">
                <a class="accordion-toggle active" data-toggle="collapse" data-parent="#accordion2" href="#collapseOne">
                              <i class="icon-minus"></i> Lorem ipsum dolor sit amet? </a>
              </div>-->
              <div id="collapseOne" class="accordion-body collapse in">
                <div class="accordion-inner">
                  We have our readymade products. These products are being used by our valuable customer in various sectors. 
                  Based on user's requirements we can develop new features and module using our developed products.
                   We are also capable of developing any new type solution as per customer's demand. 
                   Please find below information of our own products. 


                <br><br>


    <h3>ARD Smart ERP: </h3>This is an ERP framework which can handle any small, medium and enterprise type business. We have different type of package as per business requirement. We offer customer "Economy" and "Premium" package to our customer. Our ERP solution have different module or features like HR module, Account module, Inventory Module, Finance Module, Reporting Module, Project Track module etc. Customer can choose the module as per their need. 


<br><br>

<h3>E-commerce Framework:</h3> We have our readymade E-commerce platform which can be expandable from small to large scale. Based on user's need we design and develop our e-commerce solution. Our e-commerce solutions have new analytics related feature and we also use AI based modern feature as per user's demand 

<br><br>
<h3>Optical Character Recognition (OCR):  </h3>We have our OCR solution which is capable of handling Bengali and English language too. We can integrate our OCR solution in API based solution, web interface, mobile interface etc. Our OCR solution can be hosted in cloud environment or any custom server as per user's demand. 
<br><br>
<h3>Robotic Process Automation (RPA) Tool:</h3>
We have developed a RPA based tool which can perform any scheduled work as per given logic or criteria. Our RPA tool generates log and report for every task and process. It can handle lots of process or task within short period of time. 

<br><br>

<h3>Bulk SMS and e-mail tool for digital market:  </h3>
We have developed a bulk SMS and e-mail tool for our digital market related customer. Our solution can be operated in web based environment or any desktop based environment. 


                </div>
              </div>
            </div>
          </div>
          <!--end: Accordion -->

          

        </div> 

      </div>
    </div>
  </section>
  <app-footer></app-footer>

