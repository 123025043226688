import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA , NgModule} from '@angular/core';
/*@NgModule({

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})*/
@Component({
  selector: 'app-finishedpro',
  templateUrl: './finishedpro.component.html',
  styleUrls: ['./finishedpro.component.css']
})
export class FinishedproComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
