<section id="inner-headline">
    <div class="container">
      <div class="row">
        <div class="span12">
          <div class="inner-heading">
            <ul class="breadcrumb">
              <li><a href="">Home</a> <i class="icon-angle-right"></i></li>
              <li><a href="">Pages</a> <i class="icon-angle-right"></i></li>
              <li class="active">Client List</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="container">
      <div class="row">

        <div class="span12">
          <h4 class="title"><strong>Client List</strong></h4>
          <!-- start: Accordion -->
          <div class="accordion" id="accordion2">
            <div class="accordion-group">
              <div id="collapseOne" class="accordion-body collapse in">
                <div class="accordion-inner">
                We have valuable clients in below sectors.<br><br><br>

<b>1.	Corporate industry ( Group of company):</b><br>
A.	Digonta Group.<br><br>

<b>2.	Aviation Industry:</b><br>
A.	Bangladesh Flying Academy.<br><br>

<b>3.	Garments industry:</b><br>
A.	Digonta Fashion.<br><br>

<b>4.	Hotel and Tourism:</b><br>
A.	Holiday Home BD.<br><br>

<b>5.	ERP Solution:</b><br>
A.	Tech360<br><br>

<b>6.	Publication industry:</b><br>
A.	Amriter Shondhane.
                  </div>
              </div>
            </div>
          </div>
          <!--end: Accordion -->

          

        </div>

      </div>
    </div>
  </section>
  <app-footer></app-footer>
