<section id="inner-headline">
    <div class="container">
      <div class="row">
        <div class="span12">
          <div class="inner-heading">
            <ul class="breadcrumb">
              <li><a href="">Home</a> <i class="icon-angle-right"></i></li>
              <li><a href="">Pages</a> <i class="icon-angle-right"></i></li>
              <li class="active">Finished Project</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="container">
      <div class="row">

        <div class="span12">
          <h4 class="title"><strong>Finished Project</strong></h4>
          <!-- start: Accordion -->
          <div class="accordion" id="accordion2">
            <div class="accordion-group">
              <div id="collapseOne" class="accordion-body collapse in">
                <div class="accordion-inner">
                
<b>Finished project:</b><br><br>
1.	Digonta group.<br>
2.	Digonta fashion.<br>
3.	Bangladesh Flying Academy.<br>
4.	Holiday home BD.<br>
5.	Iot Lab BD.<br>
6.	Tech360.<br>
7.	Amriter Shondahne.

               </div>
              </div>
            </div>
          </div>
          <!--end: Accordion -->

          

        </div>

      </div>
    </div>
  </section>
  <app-footer></app-footer>
