import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA , NgModule} from '@angular/core';
/*@NgModule({

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})*/

@Component({
  selector: 'app-clientlist',
  templateUrl: './clientlist.component.html',
  styleUrls: ['./clientlist.component.css']
})
export class ClientlistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
