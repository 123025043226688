import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {AppComponent} from '../app/app.component';
import {MainpageComponent} from '../app/mainpage/mainpage.component';
import {OurproductComponent} from '../app/ourproduct/ourproduct.component';
import {WhoweareComponent} from '../app/whoweare/whoweare.component';
import {MsgchairmanComponent} from '../app/msgchairman/msgchairman.component';
import {ClientlistComponent} from '../app/clientlist/clientlist.component';
import {OngoingproComponent} from '../app/ongoingpro/ongoingpro.component';
import {FinishedproComponent} from '../app/finishedpro/finishedpro.component';
import {ContactusComponent} from '../app/contactus/contactus.component';
import {AboutusComponent} from '../app/aboutus/aboutus.component';


const routes: Routes = [

  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'product',
    component: OurproductComponent
  },
  {
    path: 'home',
    component: MainpageComponent
  },
  {
    path: 'whoweare',
    component: WhoweareComponent
  },
  {
    path: 'msgchairman',
    component: MsgchairmanComponent
  },

  {
    path: 'clientlist',
    component: ClientlistComponent
  },
  
  {
    path: 'ongoingproject',
    component: OngoingproComponent
  },

  {
    path: 'finishedproject',
    component: FinishedproComponent
  },

  {
    path: 'contactus',
    component: ContactusComponent
  },

  {
    path: 'aboutus',
    component: AboutusComponent
  }
  

  /*{ 
    path: 'dashboard', 
    component: DashboardComponent , 
    canActivate: [AuthGuard]
  }*/

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
