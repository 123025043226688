<section id="inner-headline">
    <div class="container">
      <div class="row">
        <div class="span12">
          <div class="inner-heading">
            <ul class="breadcrumb">
              <li><a href="">Home</a> <i class="icon-angle-right"></i></li>
              <li><a href="">Pages</a> <i class="icon-angle-right"></i></li>
              <li class="active">About Us</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="content">
    <div class="container">
      <div class="row">

        <div class="span12">
          <h4 class="title"><strong>About</strong></h4>
          <!-- start: Accordion -->
          <div class="accordion" id="accordion2">
            <div class="accordion-group">
              <!--<div class="accordion-heading">
                <a class="accordion-toggle active" data-toggle="collapse" data-parent="#accordion2" href="#collapseOne">
                              <i class="icon-minus"></i> Lorem ipsum dolor sit amet? </a>
              </div>-->
              <div id="collapseOne" class="accordion-body collapse in">
                <div class="accordion-inner">
                  ARD Solution is the foremost purveyor of aptitude and quality through our dedicated characteristics of high performance, 
                  guarantee, expertise, teamwork and proficiency. We strive to help our 
                  clients achieve success and in turn to improve qualities. We believe in finding the right solution and technologies for customer's 
                  success.
                   We serve all stakeholders,business partners, customers ardently.
                </div>
              </div>
            </div>
          </div>
          <!--end: Accordion -->

          

        </div>

      </div>
    </div>
  </section>
  <app-footer></app-footer>
