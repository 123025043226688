import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA , NgModule} from '@angular/core';
/*@NgModule({

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})*/
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
