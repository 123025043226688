<footer>
    <div class="container">
      <div class="row">
        <div class="span4">
          <div class="widget">
            <h5 class="widgetheading">Browse pages</h5>
            <ul class="link-list">
              <li><a [routerLink]="'/product'">Our products</a></li>
              <li><a [routerLink]="'/whoweare'">Who we are</a></li>
              <li><a [routerLink]="'/ongoingproject'">Ongoing project</a></li>
              <li><a [routerLink]="'/finishedproject'">Finished project</a></li>
              <li><a [routerLink]="'/contactus'">Contact Us</a></li>
              <li><a [routerLink]="'/clientlist'">Our clients</a></li>
            </ul>

          </div>
        </div>
        <div class="span4">
          <div class="widget">
            <h5 class="widgetheading">Get in touch</h5>
            <address>
                          <strong>ARD Solution.</strong><br>
                          150/1 R.K Mission Road, Gopibagh<br>
                           Dhaka-1200, Bangladesh
                      </address>
            <p>
              <i class="icon-phone"></i> +88-01819098905 <br>
              <i class="icon-envelope-alt"></i> info@ardsyssolution.com
            </p>
          </div>
        </div>
        <div class="span4">
          <div class="widget">
            <h5 class="widgetheading">Keep following us</h5>
            <p>
              Please communicate with us for your requirement.
            </p>
           
          </div>
        </div>
      </div>
    </div>
    <div id="sub-footer">
      <div class="container">
        <div class="row">
          <div class="span6">
            <div class="copyright">
              <p><span>&copy; ARD Solution. All right reserved {{year}}</span></p>
            </div>

          </div>

          <div class="span6">
            <div class="credits">
             
              Designed and Developed by ARD Solution
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
