import { Component, OnInit,CUSTOM_ELEMENTS_SCHEMA , NgModule} from '@angular/core';
/*@NgModule({

  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]

})*/

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
