<!-- start header -->
<header>
    <div class="top">
      <div class="container">
        <div class="row">
          <div class="span6">
            <p class="topcontact"><!-- <i class="icon-phone"></i>-->ARD Solution &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; Call: +8801819098905</p>
          </div>
          <div class="span6">

            <ul class="social-network">
              <li><a href="#" data-placement="bottom" title="Facebook"><i class="icon-facebook icon-white"></i></a></li>4
              <li><a href="#" data-placement="bottom" title="Linkedin"><i class="icon-linkedin icon-white"></i></a></li>
              <!--<li><a href="#" data-placement="bottom" title="Twitter"><i class="icon-twitter icon-white"></i></a></li>
             
              <li><a href="#" data-placement="bottom" title="Pinterest"><i class="icon-pinterest  icon-white"></i></a></li>
              <li><a href="#" data-placement="bottom" title="Google +"><i class="icon-google-plus icon-white"></i></a></li>
              <li><a href="#" data-placement="bottom" title="Dribbble"><i class="icon-dribbble icon-white"></i></a></li>-->
            </ul>

          </div>
        </div>
      </div>
    </div>
    <div class="container">


      <div class="row nomargin">
        <div class="span4">
          <div class="logo">
            <img src="assets/img/logo.png" alt="" />
          </div>
        </div>
        <div class="span8">
          <div class="navbar navbar-static-top">
            <div class="navigation">
              <nav>
                <ul class="nav topnav">
                  <li class="dropdown active">
                    <a href=""><!--<i class="icon-home"></i>--> Home <!--<i class="icon-angle-down"></i>--></a>
                   </li> 
                   
                  <li class="dropdown">
                    <a href="">Our Products<i class="icon-angle-down"></i></a>
                    <ul class="dropdown-menu">
                    <li> <a [routerLink]="'/product'">Our Products</a></li>
                      <li><a [routerLink]="'/whoweare'">Who we are</a></li>
                      <li><a [routerLink]="'/msgchairman'">Message of Chairman</a></li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="">Client List <i class="icon-angle-down"></i></a>
                    <ul class="dropdown-menu">
                    <li><a  [routerLink]="'/clientlist'">Client List </a></li>
                    <li><a  [routerLink]="'/finishedproject'">Finished Project</a></li>
                      <li><a  [routerLink]="'/ongoingproject'">On going Project</a></li>
                    
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a href="">Contact Us <i class="icon-angle-down"></i></a>
                      <ul class="dropdown-menu">
                      <li><a  [routerLink]="'/contactus'">Contact Us</a></li>
                      
                      </ul>
                  </li>
                 <!--<li class="dropdown">
                    <a href="https://blog.ardsyssolution.com">Blog <i class="icon-angle-down"></i></a>
                    <ul class="dropdown-menu">
                         <li><a href="https://blog.ardsyssolution.com">Blog</a></li>
                      <li><a href="https://adminblog.ardsyssolution.com">Blog login</a></li>
                      
                    
                    </ul>
                  </li>-->
                  <li  class="dropdown">
                    <a href=""> About Us <i class="icon-angle-down"></i></a>
                    <ul class="dropdown-menu">
                      <li><a  [routerLink]="'/aboutus'">About Us</a></li>
                       
                        
                    
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- end navigation -->
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- end header -->

